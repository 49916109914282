import { Apis } from '../../config';
import api from '../../ApiConfig';

const getAbout = async () =>{
    try {
        let result = await api.get(Apis.GetAbout);
        return result.data;
    } catch (error) {
        throw Error('Unable to about')
    }
}
const getTeam = async () =>{
    try {
        let result = await api.get(Apis.GetTeam);
        return result.data;
    } catch (error) {
        throw Error('Unable to team')
    }
}


const exportedObject = {
    getAbout,
    getTeam
};
export default exportedObject;