
import "react-responsive-carousel/lib/styles/carousel.min.css";
import parse from 'html-react-parser';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Banner = ({banner}) => {
    return (
      <>
        {
         
          banner && <section className='staticBanner'>
        <img src={process.env.PUBLIC_URL + "/assets/img/home-page/1.jpg"} alt="MurliWaleHausala Foundatoin"/>
        <div className="overlay">
         <div className="container">
             <div className=" flex d-column aic">
         
                     {parse(banner.title)}
                     <img src={banner.subtitle_image} alt="lock" />
                  
                     <Link to="/donate" className="link"><span>{banner.btn_text}</span></Link>
                  
                 </div>
                 {/* <div className="col-5 col-md-6 col-sm-12 col-xs-12">
                    
                 </div> */}
             </div>
         </div>

        </section>
        
        }
      </>
       
        

    )
}

export default Banner
