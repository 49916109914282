
import React,{useState} from 'react'
import Header from '../src/assets/components/Header';
import Footer from '../src/assets/components/Footer';
import Home from '../src/assets/pages/Home';
import { Switch, Route} from "react-router-dom"; 
import ScrollToTop from "./assets/components/ScrollToTop";

import AboutUs from './assets/pages/AboutUs';

import Donate from './assets/pages/donate';
import Single from './assets/pages/services/Single';
function App() {
   const [lang, setLang] = useState('en');
   const toogleLanguage = (val) =>{
      setLang(val)
   }
  return (
<>
<Header toogleLanguage={toogleLanguage} lang={lang}/>
<ScrollToTop/>
<Switch>
        <Route exact path="/">
            <Home lang={lang}/>
         </Route>
          <Route exact path="/about-us">
            <AboutUs lang={lang}/>
         </Route>
         <Route exact path="/donate">
            <Donate lang={lang}/>
         </Route>
       
         <Route exact path="/service/:slug">
            <Single lang={lang}/>
         </Route>
      
      </Switch>
    <Footer lang={lang}/>
</>
  );
}

export default App;
