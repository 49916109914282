import Sections from "../Sections"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import parse from 'html-react-parser';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const Reviews = ({ lang, homeSuccessStoryData, testimonials }) => {
  return (
    <Sections classes=" reviews">
      <div className="text-center">
        {
          homeSuccessStoryData && <>
            {
              lang === 'hi' ?
                <>
                  {parse(homeSuccessStoryData.data.hi_title)}
                  {parse(homeSuccessStoryData.data.hi_description)}
                </>
                :
                <>
                  {parse(homeSuccessStoryData.data.en_title)}
                  {parse(homeSuccessStoryData.data.en_description)}
                </>
            }


          </>
        }
      </div>
      <Carousel responsive={responsive} shouldResetAutoplay={false} autoPlay={false} >
      {
        testimonials ? testimonials.data.map((item, i)=>{
        return  <div className="reviewsbox">
          <div className="row pdnull">
            <div className="col-6 col-sm-12">
              <div className="storylink flex aic cc">
                <a href="/" className="flex aic cc">
                  <i className="i-angle-right-f" />
                </a>
              </div>
              <img className="beneficiary" src={item.video_thumbnail} alt="muraliwale" />

            </div>
            <div className="col-6 col-sm-12">
            {
              lang === 'hi' ?
                <div className="reviews-txt">
                  <h2>{item.hi_title}</h2>
                  <div className="flex"><i className="i-location"></i>
<p className="flexGrow-1">{item.hi_address}</p>
</div>
                   <div className="flex"><i className="i-support"></i>

<p className="flexGrow-1">{parse(item.hi_descripton)}</p>
</div>
                  
                </div>
                :
                <div className="reviews-txt">
                  <h2>{item.en_title}</h2>
                  <div className="flex"><i className="i-location"></i>
<p className="flexGrow-1">{item.en_address}</p>
</div>
                 <div className="flex"><i className="i-support"></i>

<p className="flexGrow-1">{parse(item.en_descripton)}</p>
</div>
                  
                  </div>
            
            }
            </div>
          </div>
        </div>
        }) : <div></div>
      }
       

      </Carousel>

    </Sections>
  )
}

export default Reviews