
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"
import { useQueries } from 'react-query';
import { GetPages } from '../helpers'
import parse from 'html-react-parser';
const AboutUs = ({ lang = 'en' }) => {
    const results = useQueries([
        { queryKey: ['aboutData', 1], queryFn: GetPages.getAbout },
        { queryKey: ['teamData', 2], queryFn: GetPages.getTeam },
    ])
    const aboutData = results[0]?.data;

    const teamData = results[1]?.data;
    const about = aboutData?.about.about_contents.find(el => el.language === lang);

    return (
        <>
            <PageBanner pagename={lang === 'en' ? "About us" : "हमारे बारे में"} icons="i-user" />

     
            {
                about ? <Sections classes="homeabt page">
                    <div className="row aic ways col-reverse">
                        <div className="col-8 col-sm-12 col-xs-12">


                            {parse(about.title)}
                            {parse(about.description)}

                        </div>
                        <div className="col-4 col-sm-12 col-xs-12">
                            <div className="flex cc abtimg">

                                <img src={aboutData.about.video_thumbnail} alt="Murliwale Hausla Foundation" />
                                <a href={aboutData.about.video_url} target="_blank" rel="noreferrer" className="youtube"><i className="i-angle-right-f"></i></a>
                            </div>

                        </div>
                    </div>
                </Sections> : null
            }

            <Sections classes=" graybg">

                {
                    lang === 'hi' ? <h1 className="mainheading"><span> हमारी</span> टीम  </h1> : <h1 className="mainheading"><span> Our</span> Team  </h1>
                }


                <div className="row multiple teammember">
                    {
                        teamData?.team_members.map((item, i) => {
                            return <div className="col-3 col-sm-6 col-xs-12">
                                <div className="team">
                                    <img src={item.photograph} alt="Murliwale Hausla Foundation" />
                                    {
                                        lang === 'hi' ? <><h2>{item.hi_name}</h2>
                                            <p>{item.hi_designation}</p></> : <><h2>{item.en_name}</h2>
                                            <p>{item.en_designation}</p></>
                                    }

                                </div>
                            </div>
                        })
                    }
                </div>
            </Sections>


        </>
    )
}

export default AboutUs
