import { useQueries } from 'react-query';
import {GetHome} from '../helpers'
import parse from 'html-react-parser';
const Footer = ({lang='en'}) => {
   const results = useQueries([
      { queryKey: ['footerData', 1], queryFn: GetHome.getFooter },
      
    ])
    const footerData = results[0]?.data;
    return (
  <footer>
        <div className='container'>
           <div className='row  multiple'>
             <div className='col-4 col-sm-12 '>
                <div className='address-box'>
                    <div className='icon-container'>
                    <i className='i-location'></i>
                    </div>
                    <div className='content'>
                    {
                     footerData?.data ? <>
                   {  lang === 'hi' ? <>
                     {parse(footerData.data.hi_box_1)}
                     </> : <>
                     {parse(footerData.data.en_box_1)}
                     </>}
                     </> : null
                    }
                    </div>
                </div>
             </div>
             <div className='col-4 col-sm-12'>
                <div className='address-box'>
                    <div className='icon-container'>
                    <i className='i-email'></i>
                    </div>
                    <div className='content'>
                    {
                     footerData?.data ? <>
                   {  lang === 'hi' ? <>
                     {parse(footerData.data.hi_box_2)}
                     </> : <>
                     {parse(footerData.data.en_box_2)}
                     </>}
                     </> : null
                    }
                    
                    </div>
                </div>
             </div>
             <div className='col-4 col-sm-12 '>
                <div className='address-box'>
                    <div className='icon-container'>
                    <i className='i-call'></i>
                    </div>
                    <div className='content'>
                    {
                     footerData?.data ? <>
                   {  lang === 'hi' ? <>
                     {parse(footerData.data.hi_box_3)}
                     </> : <>
                     {parse(footerData.data.en_box_3)}
                     </>}
                     </> : null
                    }
                    </div>
                </div>
             </div>

             <div className='col-12 '>
             <div className='follow-us flex aic cc'>
             <h4> Follow Us - </h4>
             <a href='https://youtube.com/c/MurliwaleHausla' target='_blank' className='i-youtube flex aic cc'></a>
             <a href='https://youtube.com/c/Murliwalevlogs' target='_blank' className='i-youtube flex aic cc'></a>
             <a href='https://www.facebook.com/hauslamurliwale/' target='_blank' className='i-facebook  flex aic cc'></a>
             <a href='https://twitter.com/murliwalehausla?t=GnACqjzH_i4Vb0OVoQNy6w&s=09' target='_blank' className='i-twitter  flex aic cc'></a>
             <a href='https://instagram.com/murliwalehausla24?igshid=YmMyMTA2M2Y=' target='_blank' className='i-instagram  flex aic cc'></a>
    
          
             </div>


             </div>
           </div>
           {/* <div className='footer-middle'>
           <div className='row '>
             <div className='col-4 col-sm-12'>
            
                <ul>
                  <li><a href='/'><i className='i-angle-right'></i> Animal Protection Campaign</a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Security towards animals </a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Awareness on Snakebite </a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Environmental Awareness</a></li>
              
                </ul>
             </div>
             <div className='col-4 col-sm-12 col-xs-12'>
          
                <ul>
                  <li><a href='/'><i className='i-angle-right'></i> Advancement of Education</a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Free Libraries Campaign</a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Prevent Pollution</a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Support Abilities</a></li>
                 
                </ul>
             </div>
             <div className='col-4 col-sm-12'>
           
                <ul>
                <li><a href='/'><i className='i-angle-right'></i> Social Unity Campaign</a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Youth and Women Development</a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Mass Marriage Campaign</a></li>
                  <li><a href='/'><i className='i-angle-right'></i> Water Management Campaign</a></li>
      
                </ul>
             </div>
     
           </div>
           </div> */}
          
        </div>
        <div className='footer-bottom'>
          <p>Copyright 2022 by Murliwale Hausla Foundation</p>
        </div>
     </footer>
   
   

    )
}
export default Footer
