import { Apis } from '../../config';
import api from '../../ApiConfig';

const getHomeBanner = async () =>{
    try {
        let result = await api.get(Apis.GetBanners);
        return result.data;
    } catch (error) {
        throw Error('Unable to banner')
    }
}
const getHomeAbout = async () =>{
    try {
        let result = await api.get(Apis.GetHomeAbout);
        return result.data;
    } catch (error) {
        throw Error('Unable to home about')
    }
}
const getHomeWhatwedo = async () =>{
    try {
        let result = await api.get(Apis.GetHomeWhatwedo);
        return result.data;
    } catch (error) {
        throw Error('Unable to home about')
    }
}
const getHomeSuccessStory = async () =>{
    try {
        let result = await api.get(Apis.GetHomeSuccessStory);
        return result.data;
    } catch (error) {
        throw Error('Unable to home about')
    }
}

const getFooter = async () =>{
    try {
        let result = await api.get(Apis.GetFooter);
        return result.data;
    } catch (error) {
        throw Error('Unable to get footer')
    }
}

const getTestimonials = async () =>{
    try {
        let result = await api.get(Apis.GetTestimonials);
        return result.data;
    } catch (error) {
        throw Error('Unable to get footer')
    }
}
const getServices = async () =>{
    try {
        let result = await api.get(Apis.GetServices);
        return result.data;
    } catch (error) {
        throw Error('Unable to get footer')
    }
}
const getSingleService = async (slug) =>{
    try {
        let result = await api.get(Apis.GetSingleService + slug);
        return result.data;
    } catch (error) {
        throw Error('Unable to get footer')
    }
}
const exportedObject = {
    getHomeBanner,
    getHomeAbout,
    getHomeWhatwedo,
    getHomeSuccessStory,
    getFooter,
    getTestimonials,
    getServices,
    getSingleService
};
export default exportedObject;