import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Services } from './submenu/Submenu';
// import { GetHome } from "../helpers";
// import { useQueries } from 'react-query';
function Header(props) {
    const [header, setHeader] = useState(false);
    const [toggle, settoggle] = useState(false);
    const toggler = () => {
        toggle ? settoggle(false) : settoggle(true);
    }
    const changebg = () => {
        if (window.scrollY > 40) {
            setHeader(true);
        }
        else {
            setHeader(false);
        }
    }
    window.addEventListener('scroll', changebg);
    const setLang = props.lang === 'en' ? 'hi' : 'en';
    return (
        <>
            <header className={header ? 'header active' : 'header'}>
                <div className="container nbar  csb">
                    <div className="brand"><Link to="/"><img src={process.env.PUBLIC_URL + "/assets/img/logo.svg"} alt="MurliWaleHausala Foundatoin" /></Link></div>
                    <div className="navebar">

                        <ul className={toggle ? "nav opened" : "nav"}>
                            <li><NavLink onClick={toggler} to="/" exact activeClassName="activelink"> { props.lang === 'en' ? 'HOME' : 'होम' } </NavLink></li>
                            <li className="submenu"><NavLink to="#" className=" mainnav" >{ props.lang === 'en' ? 'What we do' : 'सेवाएं' }  <i className="i-angle-down" />
                            </NavLink>
                                <Services clickr={toggler} lang = {props.lang}/>
                            </li>
                            <li><NavLink onClick={toggler} to="/about-us" activeClassName="activelink">{ props.lang === 'en' ? 'About Us' : 'हमारे बारे में' } </NavLink></li>
                            {/* <li><NavLink onClick={toggler} to="/contact-us" activeClassName="activelink">{ props.lang === 'en' ? 'Contact Us' : 'संपर्क करें' } </NavLink></li> */}



                        </ul>
                    </div>
                    <div className="flex aic">

                        <span className="callus"><NavLink className='link donates' to="/donate" exact><i className="i-donate"></i> <span>{ props.lang === 'en' ? 'DONATE' : 'दान करना' }</span></NavLink> </span>
                         {/*  <div className="language">
                       
                          <select className="" onChange={(e) => props.toogleLanguage(e.target.value)} defaultValue={props.lang}>
                                <option value='en' selected={props.lang === 'en'}>English</option>
                                <option value='hi' selected={props.lang === 'hi'}>Hindi</option>
                            </select> 
                        </div> */}
                        <button onClick={toggler} className={toggle ? "menubutton opened" : "menubutton"}>
                            <span></span><span></span><span></span>
                        </button>
                    </div>
                </div>
            </header>
            <button className="lang" onClick={() => props.toogleLanguage(setLang)}>  {props.lang === 'en' ? <img src={process.env.PUBLIC_URL + "/assets/img/hindi.svg"} alt="MurliWaleHausala Foundatoin" /> : <img src={process.env.PUBLIC_URL + "/assets/img/english.svg"} alt="MurliWaleHausala Foundatoin" />}</button>
        </>

    )
}
export default Header
