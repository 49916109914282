import Sections from "../Sections"
import { Link } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import parse from 'html-react-parser';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 10
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};
const Staps = ({ lang, whatwedoData, services }) => {
  console.log(services);

  return (
    <div className="service" >

      <div className="gradiantOR"></div>
      <Sections classes="services">
        {
          whatwedoData && <>
            {
              lang === 'hi' ?
                <>
                  {parse(whatwedoData.data.hi_title)}
                  {parse(whatwedoData.data.hi_description)}
                </>
                :
                <>
                  {parse(whatwedoData.data.en_title)}
                  {parse(whatwedoData.data.en_description)}
                </>
            }


          </>
        }
       
        <Carousel responsive={responsive} shouldResetAutoplay={false} autoPlay={false} showDots={true}>
          {
            services && services.services.length !== 0 ? services.services.map((item, i) => {
              const content = item.service_contents.find(el => el.language === lang)
              return <Link to={`/service/${item.slug}`} className="serv">
                <div className="imgs">
                  <img src={item.thumbnail} alt="muraliwale" />

                </div>
                <div className="iconser">

                  <div className="iconwraper">
                    <img src={process.env.PUBLIC_URL + "./assets/img/icons/health.svg"} alt="webtuterPedia" />
                  </div>
                </div>
               
                {parse(content.title)}
              </Link>
            }) : <div></div>
          }


        </Carousel>
        <div className="flex csb slink">
          <a href="https://youtube.com/c/MurliwaleHausla" target='_blank' className="sbutton">
            <i className="i-youtube"></i>
          </a>
          <a href="https://www.facebook.com/hauslamurliwale/" className="sbutton"  target='_blank'>
            <i className="i-facebook"></i>
          </a>
        </div>
      </Sections>
    </div>
  )
}

export default Staps
