import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/main.css';
import './assets/css/custombootstap.css';
import './assets/css/icon.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
const queryClient = new QueryClient()
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
   <Router> 
     <App />
     </Router>
     <ReactQueryDevtools initialIsOpen={false} />
</QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
