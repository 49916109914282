import Banner from "../components/homeComponents/Banner"
import Staps from "../components/homeComponents/Staps"
import HomeAbout from "../components/homeComponents/HomeAbout"
import Reviews from "../components/homeComponents/Reviews"
import { useQueries } from 'react-query';
import { GetHome } from '../helpers'
const Home = ({lang='en'}) => {
  const results = useQueries([
    { queryKey: ['homeBanner', 1], queryFn: GetHome.getHomeBanner },
    { queryKey: ['homeAbout', 2], queryFn: GetHome.getHomeAbout },
    { queryKey: ['homeWhatwedo', 3], queryFn: GetHome.getHomeWhatwedo },
    { queryKey: ['homeSuccessStory', 4], queryFn: GetHome.getHomeSuccessStory },
    { queryKey: ['testimonials', 5], queryFn: GetHome.getTestimonials },
    { queryKey: ['services', 6], queryFn: GetHome.getServices },
  ])
  const bannerData = results[0]?.data;
  const aboutData = results[1]?.data;
  const whatwedoData = results[2]?.data;
  const homeSuccessStoryData = results[3]?.data;
  const banner = bannerData?.banner.banner_contents.find(el=>el.language === lang);
  const testimonials = results[4]?.data;
  const services = results[5]?.data;
  const isLoading = results.some(result => result.isLoading);
  console.log(whatwedoData);
  console.log(services);
  
  return (
    <>
    {
      isLoading ? <div className="main_loading">
          <img src={process.env.PUBLIC_URL + "./assets/img/loader.svg"} alt=""/>
      </div> : <>
      <Banner banner={banner} />
      <HomeAbout aboutData={aboutData} lang={lang}/>
      <Staps whatwedoData={whatwedoData} lang={lang} services={services}/>
       <Reviews homeSuccessStoryData={homeSuccessStoryData} lang={lang} testimonials={testimonials}/>
      </>
    }
     
    </>
  )
}

export default Home
