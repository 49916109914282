import { NavLink } from "react-router-dom"

const SubTabs = (props) => {
    return (
        <div className="col-3 col-md-4 col-sm-12">
        <NavLink className="flex aic subtabs" exact  onClick={props.click} to={props.goTo}>
            <i className={props.icon} />
            <div className="flexGrow-1" >
               <h1>{props.title}</h1>
            
            </div>
     </NavLink>
     </div>
    )
}

export default SubTabs
