import React, { useState } from 'react'
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"

const Donate = () => {
    const [amt, setAmt] = useState(0);
    const [payDesable, setPayDesable] = useState(true);
    const handleAmtChange = (e) => {
        if (e.target.value !== 0) {
            setAmt(e.target.value);
            setPayDesable(false)
        } else {
            setPayDesable(true)
        }
    }
    return (
        <>
            <PageBanner pagename="Donate" icons="i-user" />
            {/* section */}
            <Sections classes=" graybg">
                <h1 className="mainheading donateh">Donate Our <span>Foundation</span></h1>
                <div className="row multiple teammember">
                    <div className="col-6 col-md-6 col-xs-12">
                        <div className="big-title">
                            <div className="qrcode"><img src={process.env.PUBLIC_URL + "./assets/img/qrcode.jpg"} alt="Murliwale Hausla Foundation" />
                                <h4>Murliwale Hausla Foundation</h4>

                                <p><b>UPI ID</b> : 9451204200@icici</p>
                            </div>
                            <img className='doicon' src={process.env.PUBLIC_URL + "./assets/img/donateus.svg"} alt="Murliwale Hausla Foundation" />
                            <img src={process.env.PUBLIC_URL + "./assets/img/upi-apps.png"} alt="Murliwale Hausla Foundation" />
                           
                            <i className="i-angle-down"></i>
                     <a href="upi://pay?cu=INR&amp;pa=9451204200@icici&amp;pn=Murliwale Hausla Foundation" className="donate">Donate Now</a>
                           
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-xs-12">
                        <div className="big-title text-left acountdetails" >

                            <h2>Banking Details</h2>
                            <p><b>Account Name : </b>Murliwale Hausla Foundation</p>
                            <p><b>Account Number : </b>089001002255</p>
                            <p><b>Swift code : </b>ICICINBBXXX</p>
                            <p><b>Account Name : </b>ICIC0000890</p>
                            <p><b>Account Name : </b>ICICI Bank</p>



                        </div>
                    </div>

                </div>
            </Sections>

        </>
    )
}
export default Donate
