
const API_URL = "https://mhwd.murliwalehauslafoundation.org";

const Apis = {
  GetBanners:`${API_URL}/api/banner`,
  GetAbout:`${API_URL}/api/get-about`,
  GetTeam:`${API_URL}/api/team/all`,
  GetHomeAbout:`${API_URL}/api/get-home-about`,
  GetHomeWhatwedo:`${API_URL}/api/get-home-whatwedo`,
  GetHomeSuccessStory:`${API_URL}/api/get-home-success-stroy`,
  GetFooter:`${API_URL}/api/get-footer`,
  GetTestimonials:`${API_URL}/api/get-testimonials`,
  GetServices:`${API_URL}/api/get-services`,
  GetSingleService:`${API_URL}/api/services/`,
  
};
export { API_URL, Apis };
