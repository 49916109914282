// import Sections from "../Sections"
// const HomeAbout = ({aboutData}) => {

//     return (
//         <Sections classes="homeabt">
//             <div className="row aic ways col-reverse">
//                 <div className="col-8 col-sm-12 col-xs-12">
//                     <h1 className="mainheading">Help<span> The World With</span> Gratitude!</h1>
//                     <p>Our goal is to aware not to harm animals, support humanity, be of Dowry free, the importance of education, and make them pay respect toward Atmosphere. We furnish opinions to people on how they can help them and donate to make a better society. So, stand as a reliable citizen voice against several issues which every human to get aware.</p>
//                 </div>
//                 <div className="col-4 col-sm-12 col-xs-12">
//                   <div className="flex cc abtimg">
//                       <img src={aboutData?.data.video_thumbnail} alt="Murliwale Hausla Foundation"/> 
//                       <a href="/" className="youtube"><i className="i-angle-right-f"></i></a>
//                       </div>
//                 </div>
//             </div>

//         </Sections>
//     )
// }

// export default HomeAbout
import Sections from "../Sections"
import parse from 'html-react-parser';
const HomeAbout = ({aboutData, lang}) => {
    const aboutContent = aboutData?.about.home_about_contents.find((el=>el.language === lang));
    console.log(aboutContent)

    return (
        <Sections classes="homeabt">
        {
            aboutData &&  <div className="row aic ways col-reverse">
                <div className="col-8 col-sm-12 col-xs-12">
                {
                    aboutContent && <>
                       {parse(aboutContent.title)}
                       {parse(aboutContent.description)}
                    </>
                }
               
                </div>
                <div className="col-4 col-sm-12 col-xs-12">
                  <div className="flex cc abtimg">
                      <img src={aboutData?.about.video_thumbnail} alt="Murliwale Hausla Foundation"/> 
                      <a href={aboutData?.about.video_url} target="_blank" rel="noreferrer" className="youtube"><i className="i-angle-right-f"></i></a>
                      </div>
                </div>
            </div>
        }
           

        </Sections>
    )
}

export default HomeAbout
