import {NavLink} from "react-router-dom";
import SubTabs from "./SubTabs";
export const Services = (props) => {
    const lang = props.lang;
    console.log(props.lang);
    return (
<div className="megamenu">
    <div className="backdrop">
<div className="container"> 
<div className="row gutter5 multiple5">

<SubTabs click={props.clickr} title={lang === 'en' ? "Animal Protection Campaign" : 'जानवरों का संरक्षण और सुरक्षा'} goTo="/service/animal-protection-campaign" icon="i-apc" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Security towards animals" : "समाज में जानवरों के प्रति जागरूकता और सुरक्षा की भावना पैदा करना"} goTo="/service/security-towards-animals" icon="i-sta" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Awareness on Snakebite": "समाज में सर्पदंश से फैले भय के प्रति जागरुकता फैलाना तथा सर्पों की रक्षा करना"}  goTo="/service/awareness-on-snakebite" icon="i-aos" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Environmental Awareness": "पर्यावरण संरक्षण की दिशा में काम करना"}  goTo="/service/environmental-awareness" icon="i-ea" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Advancement of Education": "शिक्षा की उन्नति के लिए शैक्षिक विकास और प्रचार के लिए विद्यालयों की स्थापना"} goTo="/service/advancement-of-education" icon="i-aoe" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Free Libraries Campaign": "ज्ञान के संवर्धन के उद्देश्य से निःशुल्क पुस्तकालयों की व्यवस्था करना"}
 goTo="/service/free-libraries-campaign" icon="i-flc" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Prevent Pollution": "पर्यावरण प्रदूषण की रोकथाम के लिए प्रयास करना"}  goTo="/service/prevent-pollution" icon="i-pp" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Support Abilities": "समाज में विकलांगों और असहायों के उत्थान के लिए कार्य करना"}  goTo="/service/support-abilities" icon="i-sa" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Social Unity Campaign": "सामाजिक एकता, साम्प्रदायिक सद्भाव शिविरों का आयोजन एवं विश्व बंधुत्व को बढ़ावा देना"}  goTo="/service/social-unity-campaign" icon="i-suc" />
<SubTabs click={props.clickr} title={lang === 'en' ? "Youth & Women Development": "युवा और महिला विकास के लिए समूहों का गठन"} goTo="/service/youth-and-women-development" icon="i-ywd" />
<SubTabs click={props.clickr} title={lang === 'en' ? "mass marriage campaign": "समाज के सभी वर्गों के सहयोग से दहेज मुक्त सामूहिक विवाह कार्यक्रम का आयोजन"}  goTo="/service/mass-marriage-campaign" icon="i-mmc" />
<SubTabs click={props.clickr} title={lang === 'en' ? "water management campaign": "ग्रामीण जल, पेयजल और मिट्टी प्रबंधन कार्यक्रम को बढ़ावा देना"}  goTo="/service/water-management-campaign" icon="i-wmc" />


</div>

</div>
</div>
</div>

    )
}


export const Vehicles = (props) => {
    return (
        <div className="megamenu">
        <div className="backdrop">
    <div>
    <NavLink onClick={props.click} to="/">Sub Menu</NavLink>
<NavLink onClick={props.click} to="/">Sub Menu2 </NavLink>
<NavLink onClick={props.click} to="/">Sub Menu3 </NavLink>
<NavLink onClick={props.click} to="/">Sub Menu4 </NavLink>
    </div>
    </div>
    </div>
    
      )
}
