import React, { useState, useEffect } from 'react'
import PageBanner from "../../components/PageBanner"
import Sections from "../../components/Sections"
import { GetHome } from '../../helpers'
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
const Single = ({ lang = 'en' }) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [serviceContent, setServiceContent] = useState(null);
    const params = useParams();

    let slug = params.slug;
    const getData = async (slug2, lang2) => {
      
        let resp = await GetHome.getSingleService(slug2);
        if (resp && resp.status) {
            const serviceContent = resp.services.service_contents.find(el => el.language === lang2);
            if (serviceContent) {
                setData(resp.services);
                setServiceContent(serviceContent)
                setLoading(false);

            }

        }
    }
    useEffect(() => {
        getData(slug, lang);
    }, [slug, lang])
    return (
        <>

            {
                isLoading ? <div className="main_loading">
                    <img src={process.env.PUBLIC_URL + "/assets/img/loader.svg"} alt="" />
                </div> : <>
                    <PageBanner icons="i-e-gear" pagename={lang === 'en' ? "What We Do" : "सेवाएं"} />


                    {/* section */}

                    <Sections classes="homeabt page">
                        <div className="row aic ways col-reverse">
                            <div className="col-8 col-sm-12 col-xs-12">
                                {parse(serviceContent.inner_top_title)}
                                {parse(serviceContent.inner_top_desc)}
                            </div>
                            <div className="col-4 col-sm-12 col-xs-12">
                                <div className="flex cc abtimg">
                                    <img src={data.innner_top_video_thumbnail} alt="Murliwale Hausla Foundation" />
                                    <a href={data.inner_top_video_url} target="_blank" rel="noreferrer" className="youtube"><i className="i-angle-right-f"></i></a>
                                </div>
                            </div>
                        </div>
                    </Sections>

                    <section className="bulls">
                        <div className="backdrops">
                            <img src={data.inner_bottom_bg} alt="MurliWaleHausala Foundatoin" />
                        </div>
                        <div className="container">
                            <div className="conetnts">
                                {parse(serviceContent.inner_bottom_contents)}

                                {/* <h3>Conservation</h3>
 <ul>
 <li>Conservation of Animals</li>
<li>Conservation of Animals is to save the endangered animals with their habitat.</li>
<li>A level ecosystem maintains by conserving living aids.</li>
<li>Having conservation techniques feel satisfied regarding the multitudinous types of species on Earth.</li>
<li>It assists in preserving a healthful and functioning environment for the survival of all organisms.</li>
<li>It also helps in repairing the damage already done to the environment.</li>

 </ul>

 <h3>Protection</h3>
 <ul>
 <li>Animals are a treasure present from God for this world.</li>
<li>Natural habitats of animals destroyed for land and agriculture.</li>
<li>Our notable animals and birds have to endanger.</li>
<li>We should stop hunting animals for fur, meat, leather, jewelry, etc.</li>
<li>Earth is a stand for animals in the feeds</li>


 </ul> */}
                            </div>
                        </div>
                    </section>

                </>
            }

        </>
    )
}

export default Single
